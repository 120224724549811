<template>
  <div>
    <slot
      name="activator"
      v-bind="{show}"
    />
    <b-modal
      v-model="display"
      :title="title"
      hide-footer
      centered
      no-body
      body-class="p-0"
    >
      <template #default="{ hide }">
        <b-form @submit.prevent="selectStudents">

          <div class="p-1">
            <b-form-group>
              <v-select
                v-model="selectedStudents"
                :options="students"
                label="username"
                placeholder="Выберите ученика"
              >
                <template #option="user">
                  <div class="d-flex align-items-center">
                    <b-avatar
                      :src="null"
                      size="32px"
                      rounded
                    />
                    <div class="ml-1">
                      <p class="mb-0">
                        {{ getStudentName(user) }}
                      </p>
                      <span class="font-small-2">{{ user.phone }}</span>
                    </div>
                  </div>
                </template>
                <template #selected-option="user">
                  <span>{{ getStudentName(user) }}</span>
                </template>
              </v-select>
            </b-form-group>
          </div>

          <div class="thin-divider" />
          <div class="p-2 d-flex align-items-center justify-content-between">
            <b-button
              variant="primary"
              type="submit"
            >
              Выбрать
            </b-button>
            <b-button
              variant="flat-secondary"
              type="button"
              @click="hide"
            >
              {{ $t('components.button.labels.cancel') }}
            </b-button>
          </div>

        </b-form>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BButton, BForm, BModal, BFormGroup, BAvatar,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import vSelect from 'vue-select'
import { getUserFullName } from '@/helpers'

export default {
  name: 'SelectSessionStudentsModal',
  components: {
    BModal,
    BButton,
    BForm,
    vSelect,
    BFormGroup,
    BAvatar,
  },
  props: {
    sessionStudents: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    students: [],
    selectedStudents: [],
    display: false,
    title: 'Выбор ученика',
  }),
  computed: {
    ...mapGetters({
      user: 'user/get_user',
    }),
  },
  watch: {
    display() {
      if (!this.sessionStudents.length) this.selectedStudents = []
    },
  },
  mounted() {
    if (this.sessionStudents.length) {
      this.selectedStudents = [...this.sessionStudents]
    } else {
      this.getStudents()
    }
  },
  methods: {
    async selectStudents() {
      this.$emit('studentsSelected', this.selectedStudents)
      this.display = false
    },
    show() {
      this.display = true
    },
    async getStudents() {
      const { items } = await this.$http.get('/user/current-teacher-student-index?expand=usersStudent')
      this.students = items
    },
    getStudentName(student) {
      return getUserFullName(student)
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-select.scss';
</style>
