<template>
  <div class="mb-1">
    <div class="d-flex align-items-center mb-1">
      <h3 class="mb-0">
        Ученик
      </h3>
      <select-session-students-modal
        :session-students="students"
        @studentsSelected="studentsSelected"
      >
        <template #activator="{show}">
          <b-button
            v-if="!students.length && editable"
            size="sm"
            class="ml-1 btn-icon"
            variant="outline-primary"
            pill
            @click="show"
          >
            <feather-icon icon="PlusIcon" />
          </b-button>
        </template>
      </select-session-students-modal>
    </div>

    <b-row v-if="loading">
      <b-col
        v-for="skel in 2"
        :key="`skel-${skel}`"
        cols="12"
      >
        <session-student-card-skeleton />
      </b-col>
    </b-row>

    <b-row v-else-if="students.length && !loading">
      <b-col
        v-for="sessionStudent in students"
        :key="sessionStudent.student_id"
        cols="12"
      >
        <session-student-card
          :session-student-id="sessionStudent.id"
          :student="sessionStudent.student"
          :removable="editable"
          class="mb-1"
          with-hometask
          :assigned-hometasks="sessionStudent.sessionStudentHomeTasks"
          @remove="removeStudent(sessionStudent)"
          @onAssignHometask="(sessionHometask) => sessionStudent.sessionStudentHomeTasks = [sessionHometask]"
          @onDetachHometask="() => sessionStudent.sessionStudentHomeTasks = []"
        />
      </b-col>
    </b-row>

    <b-row
      v-else
      class="mb-2"
    >
      <b-col>
        <p>Нет назначенных учеников</p>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol, BButton,
} from 'bootstrap-vue'
import SelectSessionStudentsModal from '@/components/session/editor/SelectSessionStudentsModal.vue'
import SessionStudentCardSkeleton from '@/components/session/editor/students/SessionStudentCardSkeleton.vue'
import SessionStudentCard from '@/components/session/editor/students/SessionStudentCard.vue'

export default {
  name: 'SessionStudents',
  components: {
    SessionStudentCard,
    SessionStudentCardSkeleton,
    SelectSessionStudentsModal,
    BRow,
    BCol,
    BButton,
  },
  props: {
    session: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    editable: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    studentsToDetach: [],
  }),
  computed: {
    students: {
      get() {
        return this.session.sessionStudents
      },
      set(newValue) {
        this.session.sessionStudents = newValue
      },
    },
  },
  methods: {
    studentsSelected(students) {
      const selectedStudents = [students]
      selectedStudents.forEach(student => {
        if (!this.students.find(existingStudent => existingStudent.student_id === student.id)) {
          this.students = [{
            student_id: student.id,
            student,
          }]
        }
      })
      this.saveStudents()
    },
    removeStudent(student) {
      this.studentsToDetach.push(student)
      this.students = this.students.filter(user => user.id !== student.id)
      this.saveStudents()
    },
    async saveStudents() {
      this.studentsToDetach = await Promise.all(this.studentsToDetach.map(async student => {
        if (student.id) await this.$http.delete(`/session-students/delete?id=${student.id}`)
        return student
      }))
      this.studentsToDetach = []

      this.students = await Promise.all(this.students.map(async sessionStudent => {
        if (!sessionStudent.id) {
          const { student_id } = sessionStudent
          const createdStudent = await this.$http.post('/session-students/create?expand=student.usersStudent,sessionStudentHomeTasks.homeTask', { session_id: this.session.id, student_id })
          return createdStudent
        }
        return sessionStudent
      }))
    },
  },
}
</script>

<style scoped>

</style>
