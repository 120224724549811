<template>
  <div>
    <span v-if="!status">Нет данных</span>
    <template v-else-if="isActive">
      <b-badge variant="info">
        {{ labels.active }}
      </b-badge>
    </template>
    <template v-else-if="isOnline">
      <b-badge variant="warning">
        {{ labels.online }}
      </b-badge>
    </template>
    <b-badge
      v-else
      variant="success"
    >
      {{ labels.passed }}
    </b-badge>
  </div>
</template>

<script>
import { BBadge } from 'bootstrap-vue'
import {
  SESSION_STATUS_ACTIVE,
  SESSION_STATUS_ONLINE,
  SESSION_STATUS_ACTIVE_LABEL,
  SESSION_STATUS_PASSED_LABEL,
  SESSION_STATUS_ONLINE_LABEL,
} from '@/shared/constants'

export default {
  name: 'StatusColumn',
  components: { BBadge },
  props: {
    status: {
      type: String,
      default: null,
    },
  },
  computed: {
    isActive() {
      return this.status === SESSION_STATUS_ACTIVE
    },
    isOnline() {
      return this.status === SESSION_STATUS_ONLINE
    },
    labels() {
      return {
        active: SESSION_STATUS_ACTIVE_LABEL,
        passed: SESSION_STATUS_PASSED_LABEL,
        online: SESSION_STATUS_ONLINE_LABEL,
      }
    },
  },
}
</script>

<style scoped>

</style>
