<template>
  <div class="mb-1">
    <b-row v-if="hasAnySteps">
      <b-col
        v-for="(hometask, lessonKey) in hometasks"
        :key="`lesson-${lessonKey}`"
        cols="12"
      >
        <div
          v-for="record in hometask.homeTasksRecords"
          :key="record.id"
        >
          <step-item
            v-if="record.step_id"
            :step="record.step"
          />
          <exercise
            v-else
            :exercise="record.exercise"
          />
        </div>
      </b-col>
    </b-row>
    <b-row
      v-else
      class="mb-2"
    >
      <b-col cols="12">
        <div class="d-flex justify-content-center text-center">
          <span v-if="!hometasks.length">Нет домашних заданий. Задание можно добавить в карточке ученика выше</span>
          <span v-else>В выбранном домашнем задании нет карточек. Вы можете добавить их в редакторе домашнего задания</span>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import StepItem from '../../steps/StepItem.vue'
import Exercise from '../../exercise/Exercise.vue'

export default {
  name: 'SessionHometasksSteps',
  components: {
    StepItem,
    Exercise,
    BRow,
    BCol,
  },
  props: {
    session: {
      type: Object,
      required: true,
    },
  },
  computed: {
    hometasks() {
      const students = this.session.sessionStudents
      return students.filter(student => !!student.sessionStudentHomeTasks?.length).map(student => student.sessionStudentHomeTasks[0]?.homeTask)
    },
    hasAnySteps() {
      let steps = 0
      this.hometasks.forEach(task => {
        steps += task.homeTasksSteps?.length || 0
      })
      return steps > 0
    },
  },
}
</script>

<style scoped>

</style>
