<template>
  <div>
    <b-row>
      <b-col
        cols="12"
        xl="9"
      >
        <div class="mb-2">
          <div
            v-if="!loading"
            class="d-flex align-items-center w-100"
          >
            <b-form
              v-if="editName"
              class="flex-fill"
              @submit.prevent="toggleNameEdit"
            >
              <b-form-group class="mb-0 ">
                <b-form-input
                  v-model="session.name"
                  placeholder="Введите название урока"
                />
              </b-form-group>
            </b-form>
            <h3
              v-else
              class="mb-0"
            >
              <span v-if="session.order">№ {{ session.order }} | </span>  {{ session.name || 'Без названия' }}
            </h3>
            <b-button
              v-if="editable"
              size="sm"
              pill
              variant="outline-primary"
              class="btn-icon ml-1"
              @click="toggleNameEdit"
            >
              <feather-icon :icon="editName ? 'CheckIcon' : 'Edit2Icon' " />
            </b-button>
          </div>
          <div v-else>
            <skeletor
              width="300px"
              height="32px"
              class="mb-50"
            />
            <div class="d-flex align-items-center">
              <skeletor
                width="120px"
                height="16px"
                class="mr-50"
              />
              <skeletor
                width="250px"
                height="12px"
              />
            </div>
          </div>

          <div
            v-if="session.id"
            class="d-flex align-items-center mt-1"
          >
            <status-column
              :status="session.status"
              class="mr-1"
            />
            <span class="text-muted">
              Последнее изменение - {{ session.updated || session.created | dateTime }}
            </span>
          </div>
        </div>

        <div class="thin-divider my-2" />

        <session-students
          ref="sessionStudents"
          :session="session"
          :loading="loading"
          :editable="editable"
        />

        <b-tabs v-model="currentTab">
          <b-tab title="Карточки к уроку">
            <session-lessons
              ref="sessionLessons"
              :session="session"
              :loading="loading"
              :editable="editable"
            />
          </b-tab>
          <b-tab title="Карточки к домашнему заданию">
            <session-hometasks-steps :session="session" />
          </b-tab>
          <b-tab title="Файлы урока">
            <images-preview
              ref="sessionStore"
              :images="session.lessonSessionsStores || []"
              :loading="loading"
            />
          </b-tab>
        </b-tabs>
      </b-col>

      <b-col
        cols="12"
        xl="3"
      >
        <div
          class="sticky-top"
          style="top: 105px"
        >
          <div
            v-if="!loading"
            class="d-flex align-items-center"
          >
            <div
              v-if="(sessionId && !finished && isOwner) || (online && !isOwner)"
              class="mr-1 mb-2 flex-fill"
            >
              <b-button
                variant="primary"
                :disabled="loading || saving"
                block
                :to="{name: 'cabinet.sessions.online', params: {session_id: sessionId}}"
              >
                <feather-icon icon="VideoIcon" />
                <span
                  class="ml-50"
                >
                  {{ online ? 'Перейти' : 'Начать' }}
                </span>
              </b-button>
            </div>

            <div
              v-if="sessionId && isOwner"
              class="mb-2 "
              :class="{'flex-fill': finished}"
            >
              <b-button
                variant="outline-danger"
                :disabled="loading || saving"
                class="btn-icon"
                title="Удалить"
                :block="finished"
                @click="deleteLesson"
              >
                <feather-icon icon="XIcon" />
                <span
                  v-if="finished"
                  class="ml-50"
                >
                  Удалить из истории
                </span>
              </b-button>
            </div>
          </div>

          <div
            v-else
            class="mb-2 d-flex align-items-center"
          >
            <div class="flex-fill">
              <skeletor
                width="100%"
                height="36px"
              />
            </div>
            <skeletor
              class="ml-1"
              width="36px"
              height="36px"
            />
          </div>

          <b-card v-if="editable">
            <b-card-title>Начало в</b-card-title>
            <b-card-text>
              <skeletor
                v-if="loading"
                width="100%"
                height="32px"
              />
              <flat-pickr
                v-else-if="editable"
                v-model="session.starts"
                class="form-control"
                :config="datePckrConfig"
                placeholder="Укажите дату и время начала урока"
                @on-close="() => saveIfEditable()"
              />
              <div v-else>
                <p class="mb-0">
                  <span v-if="session.starts">{{ session.starts | dateTime }}</span>
                  <span v-else>Нет даты</span>
                </p>
              </div>
            </b-card-text>
          </b-card>

          <b-card v-else>
            <b-card-title>Сводка</b-card-title>
            <b-card-text>
              <div class="d-flex align-items-baseline justify-content-between">
                <span v-if="online">Начат:</span>
                <span v-else>Начало в:</span>
                <p v-if="session.starts">
                  {{ session.starts | dateTime }}
                </p>
                <p v-else>
                  Дата не указана
                </p>
              </div>
              <div v-if="session.finished">
                <div class="thin-divider mb-1" />
                <div class="d-flex align-items-baseline justify-content-between">
                  <span>Завершен:</span>
                  <p>{{ session.finished | dateTime }}</p>
                </div>
                <div class="thin-divider mb-1" />
                <div class="d-flex align-items-baseline justify-content-between">
                  <span>Продолж:</span>
                  <p>{{ duration }}</p>
                </div>
              </div>
              <div v-else-if="session.online">
                <div class="thin-divider mb-1" />
                <div class="mb-0 d-flex align-items-baseline justify-content-between">
                  <span>Завершен:</span>
                  <p class="text-success">
                    Сейчас онлайн
                  </p>
                </div>
              </div>
            </b-card-text>
          </b-card>

          <b-card v-if="isOwner">
            <b-card-title>Статус</b-card-title>
            <b-card-text>
              <b-form-group>
                <template v-if="loading">
                  <skeletor
                    v-for="skeleton in 4"
                    :key="`status-skel-${skeleton}`"
                    width="100%"
                    height="20px"
                    class="mb-50"
                  />
                </template>
                <template v-else>
                  <b-form-radio-group
                    v-model="newStatus"
                    stacked
                  >
                    <b-form-radio
                      v-for="status in statuses"
                      :key="status.value"
                      :value="status.value"
                      class="mb-50"
                    >
                      {{ status.title }}
                    </b-form-radio>
                  </b-form-radio-group>
                </template>
              </b-form-group>
              <b-button
                v-if="session.status !== newStatus"
                variant="success"
                :disabled="loading || saving"
                block
                @click="saveLesson()"
              >
                <template v-if="saving">
                  <b-spinner small />
                </template>
                <template v-else>
                  <feather-icon icon="CheckIcon" />
                  Сменить статус
                </template>
              </b-button>
            </b-card-text>
          </b-card>

          <b-card v-if="editable || session.comments">
            <b-card-title>Комментарий</b-card-title>
            <b-card-text>
              <b-form-group>
                <skeletor
                  v-if="loading"
                  width="100%"
                  height="90px"
                />
                <b-form-textarea
                  v-else-if="editable"
                  v-model="session.comments"
                  :readonly="!editable"
                  no-resize
                  rows="3"
                  placeholder="Введите текст комментария"
                  @blur="saveIfEditable()"
                />
                <p v-else>
                  {{ session.comments }}
                </p>
              </b-form-group>
            </b-card-text>
          </b-card>
        </div>
      </b-col>
    </b-row>

    <confirm-modal
      ref="deleteConfirm"
      :yes="'Удалить'"
      color="danger"
      :text="`Данный урок будет удален`"
    />
  </div>
</template>

<script>
import {
  BRow, BCol,
  BCard, BCardTitle, BCardText,
  BButton, BFormRadioGroup, BFormRadio,
  BFormGroup, BFormTextarea, BFormInput, BForm,
  BSpinner, BTabs, BTab,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import { Russian } from 'flatpickr/dist/l10n/ru'
import { mapGetters } from 'vuex'
import { format, parseISO } from 'date-fns'
import SessionStudents from '@/components/session/editor/SessionStudents.vue'
import SessionLessons from '@/components/session/editor/SessionLessons.vue'
import ConfirmModal from '@/components/page-elements/modals/ConfirmModal.vue'
import {
  SESSION_STATUS_ACTIVE,
  SESSION_STATUS_ACTIVE_LABEL, SESSION_STATUS_CANCELED, SESSION_STATUS_CANCELED_LABEL,
  SESSION_STATUS_ONLINE, SESSION_STATUS_ONLINE_LABEL,
  SESSION_STATUS_PASSED, SESSION_STATUS_PASSED_LABEL,
  SESSION_STATUS_RESCHEDULED, SESSION_STATUS_RESCHEDULED_LABEL,
} from '@/shared/constants'
import StatusColumn from '@/components/session/table/StatusColumn.vue'
import { diffBetweenDates } from '@/helpers'
import SessionHometasksSteps from '../../components/session/editor/SessionHometasksSteps.vue'
import { teacherRole, userRoles } from '../../store/user'
import ImagesPreview from '../../components/session/editor/ImagesPreview.vue'

export default {
  name: 'Session',
  components: {
    ImagesPreview,
    SessionHometasksSteps,
    StatusColumn,
    ConfirmModal,
    SessionLessons,
    SessionStudents,
    BRow,
    BCol,
    BCard,
    BCardText,
    BButton,
    BCardTitle,
    flatPickr,
    BFormGroup,
    BFormTextarea,
    BFormInput,
    BForm,
    BSpinner,
    BTabs,
    BTab,
    BFormRadioGroup,
    BFormRadio,
  },
  data: () => ({
    currentTab: 0,
    session: {
      sessionStudents: [],
      sessionSteps: [],
    },
    datePckrConfig: {
      enableTime: true,
      locale: Russian,
      altFormat: 'd.m.Y H:i',
      altInput: true,
    },
    editName: false,
    loading: false,
    saving: false,
    newStatus: null,
  }),
  computed: {
    ...mapGetters({
      user: 'user/get_user',
    }),
    userRole() {
      return this.user ? userRoles[this.user.role] : ''
    },
    isTeacher() {
      return this.userRole === teacherRole
    },
    sessionId() {
      return this.$route.params.session_id
    },
    expand() {
      return 'sessionStudents.student.usersStudent,'
          + 'sessionStudents.sessionStudentHomeTasks.homeTask.homeTasksRecords.step.homeTasksStepElements.image,'
          + 'sessionStudents.sessionStudentHomeTasks.homeTask.homeTasksRecords.exercise,'
          + 'sessionSteps.step.stepElements.image,'
          + 'sessionSteps.exercise,'
          + 'lessonSessionsStores.store'
    },
    online() {
      return this.session.status === SESSION_STATUS_ONLINE
    },
    finished() {
      return this.session.status === SESSION_STATUS_PASSED
    },
    editable() {
      return ![SESSION_STATUS_PASSED, SESSION_STATUS_ONLINE].includes(this.session.status) && this.isOwner
    },
    duration() {
      return diffBetweenDates(this.session.starts, this.session.finished)
    },
    isOwner() {
      return this.session.teacher_id === this.user.id
    },
    statuses() {
      return [
        {
          title: SESSION_STATUS_ACTIVE_LABEL,
          value: SESSION_STATUS_ACTIVE,
        },
        {
          title: SESSION_STATUS_ONLINE_LABEL,
          value: SESSION_STATUS_ONLINE,
        },
        {
          title: SESSION_STATUS_PASSED_LABEL,
          value: SESSION_STATUS_PASSED,
        },
        {
          title: SESSION_STATUS_RESCHEDULED_LABEL,
          value: SESSION_STATUS_RESCHEDULED,
        },
        {
          title: SESSION_STATUS_CANCELED_LABEL,
          value: SESSION_STATUS_CANCELED,
        },
      ]
    },
  },
  watch: {
    'session.status': {
      handler(newValue) {
        this.newStatus = newValue
      },
    },
  },
  async beforeMount() {
    this.session.teacher_id = this.user.id

    if (this.sessionId) {
      this.loading = true
      const session = await this.$http.get(`/sessions/view?id=${this.sessionId}`, {
        params: {
          expand: this.expand,
        },
      }).catch(error => {
        if (error.status === 404) this.$router.push({ name: 'cabinet.sessions' })
      })
      this.session = { ...session }
      this.loading = false
    } else {
      this.saveIfEditable()
    }
  },
  methods: {
    toggleNameEdit() {
      if (this.editName) {
        this.saveIfEditable()
      } else {
        this.editName = !this.editName
      }
    },
    saveIfEditable() {
      if (!this.editable) return
      this.saveLesson()
    },
    async saveLesson() {
      this.saving = true
      const starts = this.session.starts && format(parseISO(this.session.starts, 'dd.MM.yyyy HH:mm', new Date()), 'yyyy-MM-dd HH:mm:00')
      const {
        name, comments, order, study_program_id,
      } = this.session

      const data = {
        name, comments, order, study_program_id, starts, teacher_id: this.user.id, status: this.newStatus,
      }

      if (!this.session.id) {
        const { id, updated, status } = await this.$http.post('/sessions/create', { ...data, status: SESSION_STATUS_ACTIVE })
        this.session = {
          ...this.session, id, updated, status,
        }
        this.$router.replace({ name: 'cabinet.session', params: { session_id: id } })
      } else {
        const { updated, created, status } = await this.$http.put(`/sessions/update?id=${this.sessionId}`, data).catch(() => {
          this.saving = false
        })
        this.session = {
          ...this.session, created, updated, status,
        }
      }

      this.$chatsHttp.get(`/sessions/${this.session.id}/created`)

      this.saving = false
      this.editName = false
    },
    deleteLesson() {
      this.$refs.deleteConfirm.open()
        .then(async promise => {
          if (promise.result) {
            this.$store.dispatch('sessions/deleteSession', this.session.id).then(() => {
              this.$router.push({ name: 'cabinet.sessions' })
            })
          }
        })
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-flatpicker.scss';
</style>
