<template>
  <b-card :no-body="noBody">
    <div class="d-flex align-items-center">
      <skeletor
        width="32px"
        height="32px"
        class="mr-1 rounded-circle"
      />
      <div>
        <skeletor
          width="160px"
          height="15px"
          class="mb-50"
        />
        <skeletor
          width="120px"
          height="11px"
        />
      </div>
      <div class="flex-fill" />
      <skeletor
        width="12px"
        height="12px"
      />
    </div>
  </b-card>
</template>

<script>
import { BCard } from 'bootstrap-vue'

export default {
  name: 'SessionStudentCardSkeleton',
  components: {
    BCard,
  },
  props: {
    noBody: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style scoped>

</style>
