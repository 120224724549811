<template>
  <div class="mb-1">
    <div class="d-flex align-items-center justify-content-between mb-1">
      <h4 class="mb-0">
        Материалы
      </h4>
      <div class="flex-fill" />
      <select-lessons-modal
        ref="selectionModal"
        :selected-steps="selectedSteps"
        @stepSelected="selectStep"
      >
        <template #activator="{show}">
          <b-button
            v-if="editable"
            size="sm"
            variant="primary"
            @click="show"
          >
            <feather-icon
              icon="PlusIcon"
              class="mr-50"
            />
            Добавить
          </b-button>
        </template>
      </select-lessons-modal>
    </div>
    <b-row v-if="loading">
      <b-col
        v-for="(skel) in 2"
        :key="`lesson-skel-${skel}`"
        cols="12"
      >
        <b-card>
          <div class="d-flex align-items-center">
            <skeletor
              width="180px"
              height="24px"
            />
            <div class="flex-fill" />
            <skeletor
              width="24px"
              height="24px"
            />
          </div>

          <div class="d-flex align-items-center mt-1">
            <skeletor
              width="100px"
              height="16px"
              class="mr-1"
            />
            <skeletor
              width="150px"
              height="15px"
              class="mr-1"
            />
          </div>

          <div class="d-flex align-items-end mt-1">
            <skeletor
              v-for="skel in 3"
              :key="`image-skel-${skel}`"
              width="120px"
              height="80px"
              class="mr-1"
            />
            <div class="flex-fill" />
            <skeletor
              width="140px"
              height="32px"
            />
          </div>

        </b-card>
      </b-col>
    </b-row>
    <b-row v-else-if="sessionSteps.length">
      <b-col cols="12">
        <draggable
          v-model="sessionSteps"
          tag="div"
          handle=".step-drag-handler"
          v-bind="dragOptions"
          ghost-class="ghost"
          chosen-class="chosen"
          drag-class="drag"
          :force-fallback="true"
          @change="saveSteps()"
          @start="drag = true"
          @end="drag = false"
        >
          <transition-group
            type="transition"
            :name="!drag ? 'flip-list' : null"
          >
            <div
              v-for="(step, index) in sessionSteps"
              :key="`step-${index}`"
            >
              <step-item
                v-if="step.step_id"
                :step="step.step"
                :draggable="editable"
                :removable="editable"
                in-session
                @remove="removeSelectedStep(step)"
              />
              <exercise
                v-else
                :draggable="editable"
                :exercise="step.exercise"
                :removable="editable"
                in-session
                @onRemove="removeSelectedStep(step)"
              />
            </div>
          </transition-group>
        </draggable>
      </b-col>
    </b-row>
    <b-row
      v-else
      class="mb-2"
    >
      <b-col cols="12">
        <div class="d-flex justify-content-center text-center">
          К уроку не добавлены материалы
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BButton,
} from 'bootstrap-vue'
import draggable from 'vuedraggable'
import SelectLessonsModal from '@/components/session/editor/SelectLessonsModal.vue'
import StepItem from '@/components/steps/StepItem.vue'
import Exercise from '../../exercise/Exercise.vue'

export default {
  name: 'SessionLessons',
  components: {
    Exercise,
    StepItem,
    SelectLessonsModal,
    BCard,
    BRow,
    BCol,
    BButton,
    draggable,
  },
  props: {
    session: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    editable: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    stepsToDetach: [],
    drag: false,
  }),
  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: 'steps',
        disabled: false,
      }
    },
    selectedSteps() {
      return this.sessionSteps
    },
    sessionSteps: {
      get() {
        return this.session.sessionSteps
      },
      set(newValue) {
        // eslint-disable-next-line vue/no-mutating-props
        this.session.sessionSteps = newValue
      },
    },
  },
  methods: {
    async selectStep(selectedStep) {
      const stepData = { ...selectedStep } // {step_id?, exercise_id?, step?, exercise?, toDelete}
      const existingStepKey = Object.keys(this.sessionSteps).find(key => {
        const { step_id: stepId, exercise_id: exerciseId } = this.sessionSteps[key]
        return (stepId && stepId === stepData.step_id) || (exerciseId && exerciseId === stepData.exercise_id)
      })
      if (existingStepKey === undefined) {
        const savedStep = await this.saveStep(stepData, this.sessionSteps.length - 1)
        this.sessionSteps.push({ ...savedStep })
      } else if (stepData.toDelete) {
        await this.removeSelectedStep(stepData)
      }

      this.$refs.selectionModal.close()
    },
    async removeSelectedStep(step) {
      const steps = this.session.sessionSteps
      const existingStepKey = Object.keys(steps).find(key => (steps[key].step_id ? steps[key].step_id === step.step_id : steps[key].exercise_id === step.exercise_id))
      const existingStep = steps[existingStepKey]
      if (existingStep.id) await this.$http.delete(`/session-steps/delete?id=${existingStep.id}`)
      steps.splice(existingStepKey, 1)
    },
    async saveStep(step, order) {
      const sessionStep = { ...step, session_id: this.session.id, order }
      if (!sessionStep.id) {
        const { id } = await this.$http.post('/session-steps/create', {
          session_id: sessionStep.session_id,
          step_id: sessionStep.step_id,
          exercise_id: sessionStep.exercise_id,
          order,
        })
        sessionStep.id = id
      } else {
        await this.$http.put(`/session-steps/update?id=${sessionStep.id}`, sessionStep)
      }

      return sessionStep
    },
    async saveSteps() {
      this.sessionSteps = await Promise.all(this.sessionSteps.map(async (sessionStepObj, order) => {
        const sessionStep = await this.saveStep(sessionStepObj, order)
        return sessionStep
      }))
    },
  },
}
</script>

<style scoped lang="scss">
.chosen {
  opacity: 1!important;
}
.ghost {
  opacity: 0.5!important;
}
</style>
